export const config = {
  cloudMessaging: {
    vapidKey:
      'BNg04rRat94mcemMQgPwQe35EaJvOf9LkkaKqNoUa3Yyx4c6WEKShKb6JudmK38VLpmubwKfe781BRYSRnezhrg',
  },
  backend: {
    externalApiUrl: 'https://api.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn.curipod.com',
  },
  sentry: {
    dsn: 'https://a46739c4f3e147b6a07b3f8636ee043a@sentry.io/1757705',
  },
  stripe: {
    publicKey: 'pk_live_KqY7Og9T3kZdWfnNzHf2FYEK00b4VMogqD',
  },
  appVersion: process.env.REACT_APP_VERSION,
  mixpanel: {
    token: 'b46a26a9b370ce2c5bdf57ee1092b62b',
  },
  azure: {
    tenant: 'snapmentorprod.onmicrosoft.com',
    tenantName: 'snapmentorprod',
    clientId: '47e477d5-a501-44cd-8599-817b011b2a81',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentorprod.onmicrosoft.com/47e477d5-a501-44cd-8599-817b011b2a81/User.Read',
    ],
    enterpriseRedirectUri: 'https://id.curipod.com',
    prRedirectUri: '',
    redirectUri: 'https://app.curipod.com/oauth/callback',
  },
  onboarding: {
    version: 2,
  },
  whatsNew: {
    version: 8,
  },
  game: {
    url: 'curi.live',
    apiUrl: 'https://api-live.curipod.com',
  },
  legacyApp: {
    url: 'https://app.curipod.com',
  },
  externalLinks: {
    howItWorks: 'https://curipod.com/how-it-works',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod',
    refreshInterval: 60 * 5,
  },
  discovery: {
    generatorIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
      '2381937b-df71-4c2f-92a4-9bce03f5541e', // Vocabulary meme battle
      '4782004a-3d23-4a93-8c6d-61d6609b9e69', // full lesson - bilingual
      '4976a2b9-cc8a-4900-b05e-2f9f7ac02ef9', // Curify my slides
      '6c3b4137-0dbd-40ec-b228-5515a34fb52f', // Curi-Quiz
      '526687e6-db4a-4174-9f14-61297d8bec53', // Vocabulary Hustle by Curipod creator studio
      '713cacb6-e856-4d8d-a314-564b994d7f6a', // Writing superpower
      'de157dd4-6eb9-48af-b9de-dad096675ea9', // Feedback from historical figure
      'f6376bd4-94f3-41b3-a3bd-d3bf9a47b708', // Co-write with AI
      '6ff39a67-250f-49bb-86be-36c9041991c7', // Feedback in spanish
      '1df6375c-9d71-4a8d-a0c9-254d8a21adf8', // Convince the Evil AI
      '3d74e28b-f2d3-481f-b836-ba4b89faa9d2', // pdf to comprehension questions
      '35a78cb0-5674-4a76-9c5b-d0a27e8b49f2', // Do you infer the country?
      'c81156a0-79bf-449b-9c28-c30012c7adc1', // paragraph of the week
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
      '5b20be54-64ad-42fc-a964-a062b5604e0b', // would you rather
    ],
    fullLessonIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
    ],
    fullLessonIdsSocialStudies: [
      'ebe9ae37-ac72-488d-ae37-023d5e3e46e1', // full lesson social studies
    ],
    fullLessonIdsMath: [
      'b3805995-8c62-4be4-ad44-ba5a0ccd940c', // full lesson math
    ],
    fullLessonIdsScience: [
      '9edbf2d9-472e-492c-ac29-f0201f13aed7', // full lesson science
    ],
    didNotFindTryTheseSuggestionIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
      '4976a2b9-cc8a-4900-b05e-2f9f7ac02ef9', // Curify my slides
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
    ],
    recommended: [
      '122308b0-1a9c-4dc4-b462-488a8b8d5bfc', // Give Back on Giving Tuesday - Write Now! 3/12/24
      '4244c4ec-068d-489a-a597-3ee9aab6bb2c', // What Makes a Hero? - Write Now! 19/12/24
      '0d90b4f9-30a2-4f35-9545-e5e9e63eb463', // Do You Want to Build a Snowman? - Write Now!
      '83079155-8ec2-4afb-b7f0-af9cf17c064a', // Snowman's Greatest Adventure - Write Now!
      '562ee4d4-40b0-4055-aa73-16f97f09e7c8', // Debate of the Seasons - Write Now!
      '526687e6-db4a-4174-9f14-61297d8bec53', // Vocabulary Hustle
      '2381937b-df71-4c2f-92a4-9bce03f5541e', // Vocabulary Meme Battle
      '5b20be54-64ad-42fc-a964-a062b5604e0b', // Would you rather
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
      'de157dd4-6eb9-48af-b9de-dad096675ea9', // Feedback from a historic figure
      '4136f4b3-9205-4014-9742-6a30986312fe', // Infer the fictional character
      '35a78cb0-5674-4a76-9c5b-d0a27e8b49f2', // Infer the country
    ],
  },
};
